import { store } from "reducer/store";
import { addModal, ModalType, removeModal } from "reducer/modals.slice";
import { LoginType } from "game-interface";
function showCaptcha(cb: any) {
  store.dispatch(
    addModal({
      type: ModalType.Captcha,
      cb,
    })
  );
}
function showSignup(token: string, loginType: LoginType, password?: string) {
  store.dispatch(
    addModal({
      type: ModalType.Signup,
      token,
      loginType,
      password
    })
  );
}
function showSignupByEmail(username: string) {
  store.dispatch(
    addModal({
      type: ModalType.SignupByEmail,
      username,
    })
  );
}
const ui = {
  showSignupByEmail,
  showCaptcha,
  showSignup,
};
export default ui;
