"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instantiate2Address = exports._instantiate2AddressIntermediate = void 0;
const crypto_1 = require("@cosmjs/crypto");
const encoding_1 = require("@cosmjs/encoding");
const math_1 = require("@cosmjs/math");
const utils_1 = require("@cosmjs/utils");
/**
 * The "Basic Address" Hash from
 * https://github.com/cosmos/cosmos-sdk/blob/v0.45.8/docs/architecture/adr-028-public-key-addresses.md
 */
function hash(type, key) {
    return new crypto_1.Sha256((0, crypto_1.sha256)((0, encoding_1.toAscii)(type))).update(key).digest();
}
/**
 * Takes an integer [0, 2**64-1] and returns a one-byte encoding of it.
 */
function toUint64(int) {
    return math_1.Uint64.fromNumber(int).toBytesBigEndian();
}
/**
 * Private function to export test vector data for https://github.com/cosmos/cosmjs/pull/1253.
 * Do not use in production code.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
function _instantiate2AddressIntermediate(checksum, creator, salt, msg, prefix) {
    (0, utils_1.assert)(checksum.length === 32);
    const creatorData = (0, encoding_1.fromBech32)(creator).data;
    const msgData = typeof msg === "string" ? (0, encoding_1.toUtf8)(msg) : new Uint8Array();
    // Validate inputs
    if (salt.length < 1 || salt.length > 64)
        throw new Error("Salt must be between 1 and 64 bytes");
    const key = new Uint8Array([
        ...(0, encoding_1.toAscii)("wasm"),
        0x00,
        ...toUint64(checksum.length),
        ...checksum,
        ...toUint64(creatorData.length),
        ...creatorData,
        ...toUint64(salt.length),
        ...salt,
        ...toUint64(msgData.length),
        ...msgData,
    ]);
    const addressData = hash("module", key);
    const address = (0, encoding_1.toBech32)(prefix, addressData);
    return { key, addressData, address };
}
exports._instantiate2AddressIntermediate = _instantiate2AddressIntermediate;
/**
 * Predictable address generation for the MsgInstantiateContract2
 * introduced with wasmd 0.29.
 */
function instantiate2Address(checksum, creator, salt, prefix) {
    // Non-empty msg values are discouraged.
    // See https://medium.com/cosmwasm/dev-note-3-limitations-of-instantiate2-and-how-to-deal-with-them-a3f946874230.
    const msg = null;
    return _instantiate2AddressIntermediate(checksum, creator, salt, msg, prefix).address;
}
exports.instantiate2Address = instantiate2Address;
