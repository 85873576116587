export enum LoginType {
  BSC,
  Terra,
  Email,
  Avalanche,
  Facebook,
  Google,
  GoogleMobile,
  SavedToken,
  Okex,
  Apple,
  Guest,
  Aura,
  Twitter,
  Other,
  Web3Auth,
}
export enum Chain {
  BSC,
  Terra,
  Avalanche,
  Okex,
  Aura
}

export enum NFTType {
  Mongen,
  SoulCore,
  Land,
  Plot,
  EvolutionItem,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
  TicketCO,
  TicketUC,
  TicketRA,
  TicketEP,
  TicketLE,
  TicketGE,
  TicketGL,
  Pigment,
  Box,
  ElectiveStoneCO,
  ElectiveRuneStoneCO,
  ElectiveStoneUC,
  ElectiveRuneStoneUC,
  ElectiveStoneRA,
  ElectiveRuneStoneRA,
  ElectiveStoneEP,
  ElectiveRuneStoneEP,
  ElectiveStoneLE,
  ElectiveRuneStoneLE,
  Elixir,
  MutatedGen,
  Rune,
  PlotSkin
}

export interface GasPrice {
  denom: string;
  price: number;
}

export enum KEPLR_ERRORS {
  Success = 'OK',
  Failed = 'FAILED',
  NoChainInfo = 'THERE IS NO CHAIN INFO FOR',
  SameChain = 'SAME CHAIN IS ALREADY REGISTERED',
  NotRegistered = 'CHAIN IS NOT REGISTERED',
  RequestRejected = 'REQUEST REJECTED',
  NotInstall = 'NOT INSTALL',
  NOT_EXIST = "KEY DOESN'T EXIST",
}

export enum WALLET_PROVIDER {
  KEPLR = 'KEPLR',
  COIN98 = 'COIN98',
}

export enum TRANSACTION_TYPE_ENUM {
  IBCTransfer = '/ibc.applications.transfer.v1.MsgTransfer',
  IBCReceived = '/ibc.core.channel.v1.MsgRecvPacket',
  IBCAcknowledgement = '/ibc.core.channel.v1.MsgAcknowledgement',
  IBCUpdateClient = '/ibc.core.client.v1.MsgUpdateClient',
  IBCTimeout = '/ibc.core.channel.v1.MsgTimeout',
  IBCChannelOpenInit = '/ibc.core.channel.v1.MsgChannelOpenInit',
  IBCConnectionOpenInit = '/ibc.core.connection.v1.MsgConnectionOpenInit',
  IBCCreateClient = '/ibc.core.client.v1.MsgCreateClient',
  IBCChannelOpenAck = '/ibc.core.channel.v1.MsgChannelOpenAck',
  Send = '/cosmos.bank.v1beta1.MsgSend',
  MultiSend = '/cosmos.bank.v1beta1.MsgMultiSend',
  Delegate = '/cosmos.staking.v1beta1.MsgDelegate',
  Undelegate = '/cosmos.staking.v1beta1.MsgUndelegate',
  Redelegate = '/cosmos.staking.v1beta1.MsgBeginRedelegate',
  GetReward = '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
  SwapWithinBatch = '/tendermint.liquidity.v1beta1.MsgSwapWithinBatch',
  DepositWithinBatch = '/tendermint.liquidity.v1beta1.MsgDepositWithinBatch',
  EditValidator = '/cosmos.staking.v1beta1.MsgEditValidator',
  CreateValidator = '/cosmos.staking.v1beta1.MsgCreateValidator',
  Unjail = '/cosmos.slashing.v1beta1.MsgUnjail',
  StoreCode = '/cosmwasm.wasm.v1.MsgStoreCode',
  InstantiateContract = '/cosmwasm.wasm.v1.MsgInstantiateContract',
  InstantiateContract2 = '/cosmwasm.wasm.v1.MsgInstantiateContract2',
  ExecuteContract = '/cosmwasm.wasm.v1.MsgExecuteContract',
  ModifyWithdrawAddress = '/cosmos.distribution.v1beta1.MsgSetWithdrawAddress',
  JoinPool = '/osmosis.gamm.v1beta1.MsgJoinPool',
  LockTokens = '/osmosis.lockup.MsgLockTokens',
  JoinSwapExternAmountIn = '/osmosis.gamm.v1beta1.MsgJoinSwapExternAmountIn',
  SwapExactAmountIn = '/osmosis.gamm.v1beta1.MsgSwapExactAmountIn',
  BeginUnlocking = '/osmosis.lockup.MsgBeginUnlocking',
  Vote = '/cosmos.gov.v1beta1.MsgVote',
  Vesting = '/cosmos.vesting.v1beta1.MsgCreateVestingAccount',
  Deposit = '/cosmos.gov.v1beta1.MsgDeposit',
  SubmitProposalTx = '/cosmos.gov.v1beta1.MsgSubmitProposal',
  GetRewardCommission = '/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission',
  PeriodicVestingAccount = '/cosmos.vesting.v1beta1.MsgCreatePeriodicVestingAccount',
  BasicAllowance = '/cosmos.feegrant.v1beta1.BasicAllowance',
  PeriodicAllowance = '/cosmos.feegrant.v1beta1.PeriodicAllowance',
  MsgGrantAllowance = '/cosmos.feegrant.v1beta1.MsgGrantAllowance',
  MsgRevokeAllowance = '/cosmos.feegrant.v1beta1.MsgRevokeAllowance',
  AllowedMsgAllowance = '/cosmos.feegrant.v1beta1.AllowedMsgAllowance',
  AllowedContractAllowance = '/cosmos.feegrant.v1beta1.AllowedContractAllowance',
  GrantAuthz = '/cosmos.authz.v1beta1.MsgGrant',
  ExecuteAuthz = '/cosmos.authz.v1beta1.MsgExec',
  RevokeAuthz = '/cosmos.authz.v1beta1.MsgRevoke',
  MsgMigrateContract = '/cosmwasm.wasm.v1.MsgMigrateContract',
  AuraExecuteContract = '/auranw.aura.wasm.MsgExecuteContract',
  Fail = 'FAILED',
}