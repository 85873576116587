import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { ModalType, removeModal } from "reducer/modals.slice";
import { useDispatch } from "react-redux";
import { CaptchaV2 } from "component/captcha";
import { Signup } from "component/signup";
import { SignupByEmail } from "component/signup-email";
export default function ModalManager({ setShowLogin, referral }: any) {
  const dispatch = useDispatch();
  const modals = useSelector((state: RootState) => state.modals);
  function closeModal(id: number) {
    dispatch(removeModal(id));
  }

  function renderModal(i: any) {
    switch (i.type) {
      case ModalType.Captcha:
        return <CaptchaV2 key={"captcha"} cb={i.cb} closeModal={() => closeModal(i.id)} />
      case ModalType.Signup:
        return <Signup key={"signup"} token={i.token} loginType={i.loginType} referral={referral} closeModal={() => closeModal(i.id)} setShowLogin={setShowLogin} password={i.password} />
      case ModalType.SignupByEmail:
        return <SignupByEmail key={"signupbyemail"} token={i.token} username={i.username} closeModal={() => closeModal(i.id)} setShowLogin={setShowLogin} />
    }

  }
  return <div>{modals.map((i: any) => renderModal(i))}</div>;
}
