import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  browserPopupRedirectResolver,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

// @ts-ignore
const firebaseConfig = window.getFirebaseConfig();
// Initialize Firebase
let app = firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();

const auth = initializeAuth(app, {
  persistence: [
    indexedDBLocalPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
  ],
});

const appleFirebaseAuth = firebase.auth();

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

googleProvider.setCustomParameters({ prompt: "select_account" });
facebookProvider.setCustomParameters({ display: "popup" });
facebookProvider.addScope("name");
facebookProvider.addScope("email");
facebookProvider.addScope("picture");
appleProvider.addScope("email");
appleProvider.addScope("name");

// export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);
// export const signInWithFacebook = () => auth.signInWithPopup(facebookProvider);

export const signInWithGoogle = async (setLoading: any) => {
  try {
    let result: any = await signInWithPopup(
      auth,
      googleProvider,
      browserPopupRedirectResolver
    );
    return result;
  } catch (err) {
    setLoading && setLoading(false);
  }
};
export const signInWithFacebook = async () => {
  let result: any = await signInWithPopup(
    auth,
    facebookProvider,
    browserPopupRedirectResolver
  );
  return result;
};
export const signInWithApple = async (setLoading: any) => {
  try {
    let result: any = await appleFirebaseAuth.signInWithPopup(appleProvider);
    const credential = result.credential;
    const user = result.user;
    console.log({ credential, user });
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;
    return { accessToken, idToken, user };
  } catch (error: any) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log({ errorCode, errorMessage });
    setLoading && setLoading(false);
    return null;
  }
};
