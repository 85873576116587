"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CosmWasmClient = void 0;
/* eslint-disable @typescript-eslint/naming-convention */
const encoding_1 = require("@cosmjs/encoding");
const math_1 = require("@cosmjs/math");
const stargate_1 = require("@cosmjs/stargate");
const tendermint_rpc_1 = require("@cosmjs/tendermint-rpc");
const utils_1 = require("@cosmjs/utils");
const abci_1 = require("cosmjs-types/cosmos/base/abci/v1beta1/abci");
const types_1 = require("cosmjs-types/cosmwasm/wasm/v1/types");
const modules_1 = require("./modules");
class CosmWasmClient {
    /**
     * Creates an instance by connecting to the given Tendermint RPC endpoint.
     *
     * This uses auto-detection to decide between a Tendermint 0.37 and 0.34 client.
     * To set the Tendermint client explicitly, use `create`.
     */
    static async connect(endpoint) {
        // Tendermint/CometBFT 0.34/0.37 auto-detection. Starting with 0.37 we seem to get reliable versions again 🎉
        // Using 0.34 as the fallback.
        let tmClient;
        const tm37Client = await tendermint_rpc_1.Tendermint37Client.connect(endpoint);
        const version = (await tm37Client.status()).nodeInfo.version;
        if (version.startsWith("0.37.")) {
            tmClient = tm37Client;
        }
        else {
            tm37Client.disconnect();
            tmClient = await tendermint_rpc_1.Tendermint34Client.connect(endpoint);
        }
        return CosmWasmClient.create(tmClient);
    }
    /**
     * Creates an instance from a manually created Tendermint client.
     * Use this to use `Tendermint37Client` instead of `Tendermint34Client`.
     */
    static async create(tmClient) {
        return new CosmWasmClient(tmClient);
    }
    constructor(tmClient) {
        this.codesCache = new Map();
        if (tmClient) {
            this.tmClient = tmClient;
            this.queryClient = stargate_1.QueryClient.withExtensions(tmClient, stargate_1.setupAuthExtension, stargate_1.setupBankExtension, modules_1.setupWasmExtension, stargate_1.setupTxExtension);
        }
    }
    getTmClient() {
        return this.tmClient;
    }
    forceGetTmClient() {
        if (!this.tmClient) {
            throw new Error("Tendermint client not available. You cannot use online functionality in offline mode.");
        }
        return this.tmClient;
    }
    getQueryClient() {
        return this.queryClient;
    }
    forceGetQueryClient() {
        if (!this.queryClient) {
            throw new Error("Query client not available. You cannot use online functionality in offline mode.");
        }
        return this.queryClient;
    }
    async getChainId() {
        if (!this.chainId) {
            const response = await this.forceGetTmClient().status();
            const chainId = response.nodeInfo.network;
            if (!chainId)
                throw new Error("Chain ID must not be empty");
            this.chainId = chainId;
        }
        return this.chainId;
    }
    async getHeight() {
        const status = await this.forceGetTmClient().status();
        return status.syncInfo.latestBlockHeight;
    }
    async getAccount(searchAddress) {
        try {
            const account = await this.forceGetQueryClient().auth.account(searchAddress);
            return account ? (0, stargate_1.accountFromAny)(account) : null;
        }
        catch (error) {
            if (/rpc error: code = NotFound/i.test(error.toString())) {
                return null;
            }
            throw error;
        }
    }
    async getSequence(address) {
        const account = await this.getAccount(address);
        if (!account) {
            throw new Error(`Account '${address}' does not exist on chain. Send some tokens there before trying to query sequence.`);
        }
        return {
            accountNumber: account.accountNumber,
            sequence: account.sequence,
        };
    }
    async getBlock(height) {
        const response = await this.forceGetTmClient().block(height);
        return {
            id: (0, encoding_1.toHex)(response.blockId.hash).toUpperCase(),
            header: {
                version: {
                    block: new math_1.Uint53(response.block.header.version.block).toString(),
                    app: new math_1.Uint53(response.block.header.version.app).toString(),
                },
                height: response.block.header.height,
                chainId: response.block.header.chainId,
                time: (0, tendermint_rpc_1.toRfc3339WithNanoseconds)(response.block.header.time),
            },
            txs: response.block.txs,
        };
    }
    async getBalance(address, searchDenom) {
        return this.forceGetQueryClient().bank.balance(address, searchDenom);
    }
    async getTx(id) {
        const results = await this.txsQuery(`tx.hash='${id}'`);
        return results[0] ?? null;
    }
    async searchTx(query) {
        let rawQuery;
        if (typeof query === "string") {
            rawQuery = query;
        }
        else if (Array.isArray(query)) {
            rawQuery = query.map((t) => `${t.key}='${t.value}'`).join(" AND ");
        }
        else {
            throw new Error("Got unsupported query type. See CosmJS 0.31 CHANGELOG for API breaking changes here.");
        }
        return this.txsQuery(rawQuery);
    }
    disconnect() {
        if (this.tmClient)
            this.tmClient.disconnect();
    }
    /**
     * Broadcasts a signed transaction to the network and monitors its inclusion in a block.
     *
     * If broadcasting is rejected by the node for some reason (e.g. because of a CheckTx failure),
     * an error is thrown.
     *
     * If the transaction is not included in a block before the provided timeout, this errors with a `TimeoutError`.
     *
     * If the transaction is included in a block, a `DeliverTxResponse` is returned. The caller then
     * usually needs to check for execution success or failure.
     */
    // NOTE: This method is tested against slow chains and timeouts in the @cosmjs/stargate package.
    // Make sure it is kept in sync!
    async broadcastTx(tx, timeoutMs = 60000, pollIntervalMs = 3000) {
        let timedOut = false;
        const txPollTimeout = setTimeout(() => {
            timedOut = true;
        }, timeoutMs);
        const pollForTx = async (txId) => {
            if (timedOut) {
                throw new stargate_1.TimeoutError(`Transaction with ID ${txId} was submitted but was not yet found on the chain. You might want to check later. There was a wait of ${timeoutMs / 1000} seconds.`, txId);
            }
            await (0, utils_1.sleep)(pollIntervalMs);
            const result = await this.getTx(txId);
            return result
                ? {
                    code: result.code,
                    height: result.height,
                    txIndex: result.txIndex,
                    rawLog: result.rawLog,
                    transactionHash: txId,
                    events: result.events,
                    msgResponses: result.msgResponses,
                    gasUsed: result.gasUsed,
                    gasWanted: result.gasWanted,
                }
                : pollForTx(txId);
        };
        const transactionId = await this.broadcastTxSync(tx);
        return new Promise((resolve, reject) => pollForTx(transactionId).then((value) => {
            clearTimeout(txPollTimeout);
            resolve(value);
        }, (error) => {
            clearTimeout(txPollTimeout);
            reject(error);
        }));
    }
    /**
     * Broadcasts a signed transaction to the network without monitoring it.
     *
     * If broadcasting is rejected by the node for some reason (e.g. because of a CheckTx failure),
     * an error is thrown.
     *
     * If the transaction is broadcasted, a `string` containing the hash of the transaction is returned. The caller then
     * usually needs to check if the transaction was included in a block and was successful.
     *
     * @returns Returns the hash of the transaction
     */
    async broadcastTxSync(tx) {
        const broadcasted = await this.forceGetTmClient().broadcastTxSync({ tx });
        if (broadcasted.code) {
            return Promise.reject(new stargate_1.BroadcastTxError(broadcasted.code, broadcasted.codespace ?? "", broadcasted.log));
        }
        const transactionId = (0, encoding_1.toHex)(broadcasted.hash).toUpperCase();
        return transactionId;
    }
    /**
     * getCodes() returns all codes and is just looping through all pagination pages.
     *
     * This is potentially inefficient and advanced apps should consider creating
     * their own query client to handle pagination together with the app's screens.
     */
    async getCodes() {
        const allCodes = [];
        let startAtKey = undefined;
        do {
            const { codeInfos, pagination } = await this.forceGetQueryClient().wasm.listCodeInfo(startAtKey);
            const loadedCodes = codeInfos || [];
            allCodes.push(...loadedCodes);
            startAtKey = pagination?.nextKey;
        } while (startAtKey?.length !== 0);
        return allCodes.map((entry) => {
            (0, utils_1.assert)(entry.creator && entry.codeId && entry.dataHash, "entry incomplete");
            return {
                id: entry.codeId.toNumber(),
                creator: entry.creator,
                checksum: (0, encoding_1.toHex)(entry.dataHash),
            };
        });
    }
    async getCodeDetails(codeId) {
        const cached = this.codesCache.get(codeId);
        if (cached)
            return cached;
        const { codeInfo, data } = await this.forceGetQueryClient().wasm.getCode(codeId);
        (0, utils_1.assert)(codeInfo && codeInfo.codeId && codeInfo.creator && codeInfo.dataHash && data, "codeInfo missing or incomplete");
        const codeDetails = {
            id: codeInfo.codeId.toNumber(),
            creator: codeInfo.creator,
            checksum: (0, encoding_1.toHex)(codeInfo.dataHash),
            data: data,
        };
        this.codesCache.set(codeId, codeDetails);
        return codeDetails;
    }
    /**
     * getContracts() returns all contract instances for one code and is just looping through all pagination pages.
     *
     * This is potentially inefficient and advanced apps should consider creating
     * their own query client to handle pagination together with the app's screens.
     */
    async getContracts(codeId) {
        const allContracts = [];
        let startAtKey = undefined;
        do {
            const { contracts, pagination } = await this.forceGetQueryClient().wasm.listContractsByCodeId(codeId, startAtKey);
            allContracts.push(...contracts);
            startAtKey = pagination?.nextKey;
        } while (startAtKey?.length !== 0 && startAtKey !== undefined);
        return allContracts;
    }
    /**
     * Returns a list of contract addresses created by the given creator.
     * This just loops through all pagination pages.
     */
    async getContractsByCreator(creator) {
        const allContracts = [];
        let startAtKey = undefined;
        do {
            const { contractAddresses, pagination } = await this.forceGetQueryClient().wasm.listContractsByCreator(creator, startAtKey);
            allContracts.push(...contractAddresses);
            startAtKey = pagination?.nextKey;
        } while (startAtKey?.length !== 0 && startAtKey !== undefined);
        return allContracts;
    }
    /**
     * Throws an error if no contract was found at the address
     */
    async getContract(address) {
        const { address: retrievedAddress, contractInfo } = await this.forceGetQueryClient().wasm.getContractInfo(address);
        if (!contractInfo)
            throw new Error(`No contract found at address "${address}"`);
        (0, utils_1.assert)(retrievedAddress, "address missing");
        (0, utils_1.assert)(contractInfo.codeId && contractInfo.creator && contractInfo.label, "contractInfo incomplete");
        return {
            address: retrievedAddress,
            codeId: contractInfo.codeId.toNumber(),
            creator: contractInfo.creator,
            admin: contractInfo.admin || undefined,
            label: contractInfo.label,
            ibcPortId: contractInfo.ibcPortId || undefined,
        };
    }
    /**
     * Throws an error if no contract was found at the address
     */
    async getContractCodeHistory(address) {
        const result = await this.forceGetQueryClient().wasm.getContractCodeHistory(address);
        if (!result)
            throw new Error(`No contract history found for address "${address}"`);
        const operations = {
            [types_1.ContractCodeHistoryOperationType.CONTRACT_CODE_HISTORY_OPERATION_TYPE_INIT]: "Init",
            [types_1.ContractCodeHistoryOperationType.CONTRACT_CODE_HISTORY_OPERATION_TYPE_GENESIS]: "Genesis",
            [types_1.ContractCodeHistoryOperationType.CONTRACT_CODE_HISTORY_OPERATION_TYPE_MIGRATE]: "Migrate",
        };
        return (result.entries || []).map((entry) => {
            (0, utils_1.assert)(entry.operation && entry.codeId && entry.msg);
            return {
                operation: operations[entry.operation],
                codeId: entry.codeId.toNumber(),
                msg: JSON.parse((0, encoding_1.fromUtf8)(entry.msg)),
            };
        });
    }
    /**
     * Returns the data at the key if present (raw contract dependent storage data)
     * or null if no data at this key.
     *
     * Promise is rejected when contract does not exist.
     */
    async queryContractRaw(address, key) {
        // just test contract existence
        await this.getContract(address);
        const { data } = await this.forceGetQueryClient().wasm.queryContractRaw(address, key);
        return data ?? null;
    }
    /**
     * Makes a smart query on the contract, returns the parsed JSON document.
     *
     * Promise is rejected when contract does not exist.
     * Promise is rejected for invalid query format.
     * Promise is rejected for invalid response format.
     */
    async queryContractSmart(address, queryMsg) {
        try {
            return await this.forceGetQueryClient().wasm.queryContractSmart(address, queryMsg);
        }
        catch (error) {
            if (error instanceof Error) {
                if (error.message.startsWith("not found: contract")) {
                    throw new Error(`No contract found at address "${address}"`);
                }
                else {
                    throw error;
                }
            }
            else {
                throw error;
            }
        }
    }
    async txsQuery(query) {
        const results = await this.forceGetTmClient().txSearchAll({ query: query });
        return results.txs.map((tx) => {
            const txMsgData = abci_1.TxMsgData.decode(tx.result.data ?? new Uint8Array());
            return {
                height: tx.height,
                txIndex: tx.index,
                hash: (0, encoding_1.toHex)(tx.hash).toUpperCase(),
                code: tx.result.code,
                events: tx.result.events.map(stargate_1.fromTendermintEvent),
                rawLog: tx.result.log || "",
                tx: tx.tx,
                msgResponses: txMsgData.msgResponses,
                gasUsed: tx.result.gasUsed,
                gasWanted: tx.result.gasWanted,
            };
        });
    }
}
exports.CosmWasmClient = CosmWasmClient;
