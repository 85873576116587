import api from "api";
import { useState } from "react";
import { toast } from "react-toastify";
import TwoFactorAuthenticator from "component/two-factor";
import { useTranslation } from "react-i18next";
import ui from "service/ui";
export default function EmailConnector({
  onDone,
  onBack,
  setShowLogin,
  type,
}: {
  onDone: Function;
  onBack: Function;
  setShowLogin: Function;
  type: "signin" | "signup";
}) {
  const [mail, setMail] = useState("");
  const [input, setInput] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [show2FASetup, setShow2FASetup] = useState(false);
  const [showOtpInput, setShowOptInput] = useState(false);
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState(null);
  const { t } = useTranslation();
  function handleChange(name: string, value: any) {
    let tmp: any = { ...input };
    tmp[name] = value;
    setInput(tmp);
  }
  async function login() {
    if (showPassword) {
      try {
        if (!(input.username && input.password)) {
          toast(t("required_username_and_password"));
          return;
        }
        let rs = await api.post("/customer/login-customer", {
          username: input.username,
          password: input.password,
          type: 2,
        });
        const { token, is_2fa_enabled, is_2fa_required } = rs;
        if (is_2fa_enabled) {
          setShowOptInput(true);
        } else if (is_2fa_required && !is_2fa_enabled) {
          localStorage.clear();
          setToken(token);
          setShow2FASetup(true);
        } else {
          setShowLogin(false);
          localStorage.clear();
          sessionStorage.setItem("token", token);
          onDone();
        }
      } catch (err: any) {
        if (err.message) {
          toast(t(err.message));
        }
      }
    } else {
      if (!input.username) {
        toast(t("required_username_or_email"));
        return;
      }
      let rs = await api.post("/customer/check-username", {
        username: input.username,
        type: 2,
      });
      if (rs.exist) {
        setShowPassword(true);
      } else {
        // toast(t("customer_not_found"));

        // HANDLE EMAIL REGISTER
        if (!input.username.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
          toast(t("customer_not_found"));
        } else {
          ui.showSignupByEmail(input.username)
        }
      }
    }
  }
  const registerByEmail = async (email: string) => {
    try {
      await api.sendRegisterEmail(email);
      onBack();
      toast(t("success_send_email_request"));
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  };

  return (
    <div>
      {show2FASetup && (
        <div className="bg bg-contain px-1 w-[600px] max-w-full">
          <TwoFactorAuthenticator
            open={show2FASetup}
            onClose={() => setShow2FASetup(false)}
            onSuccess={onDone}
            token={token}
          />
        </div>
      )}
      {showOtpInput && (
        <div
          className="w-full bg bg-contain py-4 w-[450px]"
          style={{ paddingBottom: 25 }}
        >
          <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
            {t("second_authentication_factor")}
          </div>
          <div className="w-11/12 mx-auto">
            <div className="mb-6 text-white">
              {t("second_authentication_factor_desc")}
            </div>
            <div className="text-center mx-auto justify-center w-full">
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder={t("enter_verify_code")}
                type="text"
                className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
              />
            </div>
          </div>
          <div
            className={`flex w-full justify-center gap-4 my-6 text-center items-center mx-auto`}
          >
            <button
              color="yellow"
              className="px-8 py-2"
              onClick={async () => {
                const result = await api.loginWith2FA({
                  otp,
                  email: input.username,
                  password: input.password,
                });
                if (result) {
                  setShowLogin(false);
                }
              }}
            >
              <div className="text-white font-semibold">{t("confirm")}</div>
            </button>
          </div>
        </div>
      )}
      {!show2FASetup && !showOtpInput && (
        <div
          className="bg bg-contain px-1 w-[500px] max-w-full"
          style={{ paddingBottom: 25 }}
        >
          <p className="text-5xl py-4 text-outline">{t("email_or_username")}</p>
          <form
            className="bg-[#22253a] p-4"
            onSubmit={async (evt) => {
              evt.preventDefault();
              if (type === "signin") {
                await login();
              } else {
                await registerByEmail(input.username);
              }
            }}
          >
            <div className="mt-4">
              <input
                onChange={(evt: any) => {
                  handleChange("username", evt.target.value);
                }}
                placeholder={t("email_or_username")}
                type="text"
                className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
              />
            </div>
            {showPassword && (
              <div className="mt-2">
                <input
                  onChange={(evt: any) => {
                    handleChange("password", evt.target.value);
                  }}
                  placeholder={t("password")}
                  type="password"
                  className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
                />
              </div>
            )}
            <div className="flex gap-4">
              <button
                type="button"
                className="p-2 large w-full mt-3"
                onClick={() => {
                  onBack();
                }}
              >
                <p className="text-outline">{t("back")}</p>
              </button>
              <button type="submit" className="p-2 large w-full mt-3">
                <p className="text-outline">
                  {showPassword ? t("login") : t("continue")}
                </p>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
