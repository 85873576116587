"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupWasmExtension = exports.wasmTypes = exports.isMsgUpdateAdminEncodeObject = exports.isMsgStoreCodeEncodeObject = exports.isMsgMigrateEncodeObject = exports.isMsgInstantiateContractEncodeObject = exports.isMsgInstantiateContract2EncodeObject = exports.isMsgExecuteEncodeObject = exports.isMsgClearAdminEncodeObject = exports.createWasmAminoConverters = void 0;
var aminomessages_1 = require("./wasm/aminomessages");
Object.defineProperty(exports, "createWasmAminoConverters", { enumerable: true, get: function () { return aminomessages_1.createWasmAminoConverters; } });
var messages_1 = require("./wasm/messages");
Object.defineProperty(exports, "isMsgClearAdminEncodeObject", { enumerable: true, get: function () { return messages_1.isMsgClearAdminEncodeObject; } });
Object.defineProperty(exports, "isMsgExecuteEncodeObject", { enumerable: true, get: function () { return messages_1.isMsgExecuteEncodeObject; } });
Object.defineProperty(exports, "isMsgInstantiateContract2EncodeObject", { enumerable: true, get: function () { return messages_1.isMsgInstantiateContract2EncodeObject; } });
Object.defineProperty(exports, "isMsgInstantiateContractEncodeObject", { enumerable: true, get: function () { return messages_1.isMsgInstantiateContractEncodeObject; } });
Object.defineProperty(exports, "isMsgMigrateEncodeObject", { enumerable: true, get: function () { return messages_1.isMsgMigrateEncodeObject; } });
Object.defineProperty(exports, "isMsgStoreCodeEncodeObject", { enumerable: true, get: function () { return messages_1.isMsgStoreCodeEncodeObject; } });
Object.defineProperty(exports, "isMsgUpdateAdminEncodeObject", { enumerable: true, get: function () { return messages_1.isMsgUpdateAdminEncodeObject; } });
Object.defineProperty(exports, "wasmTypes", { enumerable: true, get: function () { return messages_1.wasmTypes; } });
var queries_1 = require("./wasm/queries");
Object.defineProperty(exports, "setupWasmExtension", { enumerable: true, get: function () { return queries_1.setupWasmExtension; } });
