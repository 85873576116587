import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Suspense } from "react";
import { getChainOptions, WalletProvider } from "@terra-money/wallet-provider";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./reducer/store";
import { Provider } from "react-redux";
import "./i18n";
import "rc-slider/assets/index.css";

let persistor = persistStore(store);

getChainOptions().then((chainOptions) => {
  //@ts-ignore
  window.loadMeta().then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <WalletProvider {...chainOptions}>
            <Suspense fallback={<p>Loading</p>}>
              <App />
            </Suspense>
          </WalletProvider>
        </PersistGate>
      </Provider>,
      document.getElementById("root")
    );
  });
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
