import axios from "axios";
import keplrService from "component/keplr";
import Config from "Config";
import { LoginType, WALLET_PROVIDER } from "game-interface";
import { t } from "i18next";
import { toast } from "react-toastify";
import ui from "service/ui";
function sendGA(name: string, payload?: any) {
  // @ts-ignore
  if (window.sendGaEvent) {
    // @ts-ignore
    window.sendGaEvent(name, payload);
  }
}
//@ts-ignore
const Web3 = window.Web3;
//@ts-ignore
const ethereum = window.ethereum;
var web3 = new Web3(Web3.givenProvider);

async function login(type: LoginType) {
  window.sessionStorage.setItem("LoginType", type + "");
  try {
    let nonce = await getNonce();
    const account = await ethereum.request({ method: "eth_requestAccounts" });
    let sign = await web3.eth.personal.sign(
      nonce,
      account[0],
      type,
      "publicpassword"
    );
    let { customerInfo, token } = await post("/customer/login-customer", {
      sign,
      nonce,
      type,
    });
    localStorage.clear();
    sessionStorage.setItem("token", token);
    return { customerInfo, token };
  } catch (error: any) {
    if (error.message === "customer_is_block") {
      toast(`You have been banned following reason below: ${error.message}`);
      throw new Error();
    }
    throw error;
  }
}

async function loginCosmos(type: LoginType) {
  window.sessionStorage.setItem("LoginType", type + "");
  try {
    let nonce = await getNonce();
    let accountAddress = await keplrService.connect(WALLET_PROVIDER.KEPLR);
    console.log({ accountAddress });
    if (!accountAddress) {
      throw new Error("Not connect keplr");
    }
    await delay(500);
    let signature = await keplrService.signMessage(accountAddress, nonce);
    console.log({ signature });

    let { customerInfo, token } = await post("/customer/login-customer", {
      sign: JSON.stringify(signature),
      nonce,
      type,
    });
    localStorage.clear();
    sessionStorage.setItem("token", token);
    return { customerInfo, token };
  } catch (error: any) {
    if (error.message === "customer_is_block") {
      toast(`You have been banned following reason below: ${error.message}`);
      throw new Error();
    }
    throw error;
  }
}

async function registerMetaMask(
  type: LoginType,
  referral?: string,
  setShowLogin?: any
) {
  localStorage.clear();
  window.localStorage.setItem("LoginType", type + "");
  let nonce = await getNonce();
  const account = await ethereum.request({ method: "eth_requestAccounts" });
  let sign = await web3.eth.personal.sign(
    nonce,
    account[0],
    type,
    "publicpassword"
  );
  ui.showCaptcha(async (captcha_id: string, captcha_nonce: number) => {
    try {
      let { customerInfo, gameToken, authInfos } = await api.post(
        "/dao/register-by-wallet",
        {
          sign,
          nonce,
          type,
          referral,
          captcha_id,
          captcha_nonce,
        }
      );
      localStorage.setItem("token", gameToken);
      setShowLogin && setShowLogin(false);
      onCustomerSignup(type);
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  });
}
function onCustomerSignup(type: LoginType) {
  switch (type) {
    case LoginType.Facebook:
    case LoginType.Google:
    case LoginType.Email:
    case LoginType.GoogleMobile:
      sendGA("on_signup_social");
      break;
    default:
      sendGA("on_signup_wallet");
      break;
  }
}

async function registerWithSavedToken(
  token: string,
  type: LoginType,
  setShowLogin?: any,
  password?: string,
  referral?: string
) {
  ui.showCaptcha(async (captcha_id: string, captcha_nonce: number) => {
    try {
      let user_src = "",
        referral = "";
      if (window.location.href) {
        try {
          let search = window.location.href.split("?")[1];
          let query = new URLSearchParams(search);
          user_src = query.get("src");
          referral = query.get("code");
        } catch (error) {}
      }
      let geocountry = "unknown";
      try {
        // @ts-ignore
        let device: any = JSON.parse(await getDeviceInfo());
        geocountry = device.geocountry;
      } catch (error) {
        console.error("cannot get geo country");
      }
      let { gameToken, authInfos } = await api.post("/dao/register", {
        type,
        token,
        captcha_id,
        captcha_nonce,
        password,
        user_src,
        geocountry,
        referral,
        // @ts-ignore
        deviceinfo: window.getOS(),
      });
      localStorage.clear();
      sessionStorage.setItem("token", gameToken);
      setShowLogin && setShowLogin(false);
      onCustomerSignup(authInfos[0].D);
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  });
}

async function getNonce() {
  let rs = await post("/nonce/create-nonce", {});
  return rs.nonce;
}
async function post(url: string, data: any, version = "0"): Promise<any> {
  let rs = await fetch(`${Config.Host}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      vv: `Platform 3 ${version}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 200:
      try {
        let tmp = await rs.json();
        return tmp;
      } catch (error) {
        return error;
      }
    case 403:
      console.log("181");
      sessionStorage.clear();
      throw new Error("forbidden");
    case 423:
      try {
        let tmp = await rs.json();
        const token = tmp.token;
        const type = tmp.type;
        if (type === LoginType.Email) {
          throw new Error(tmp.code);
          // let password = data.password;
          // ui.showSignup(token, type, password);
        } else {
          ui.showSignup(token, type);
        }
        throw new Error();
      } catch (error) {
        throw error;
      }
    default:
      let err = await rs.json();
      throw err;
  }
}

async function getImage(url: string): Promise<any> {
  let token = sessionStorage.getItem("token");
  let rs = await fetch(`${Config.Host}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.blob();
      return tmp;
    case 403:
    case 405:
      console.log("222");
      sessionStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.blob();
      throw err;
  }
}

async function postByToken(
  url: string,
  data: any,
  token: string
): Promise<any> {
  let rs = await fetch(`${Config.Host}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 200:
      try {
        let tmp = await rs.json();
        return tmp;
      } catch (error) {
        return error;
      }
    case 403:
      console.log("254");
      sessionStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function getByToken(url: string, token: string): Promise<any> {
  let rs = await fetch(`${Config.Host}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      console.log("276");
      sessionStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function postFormData(
  url: string,
  data: any,
  cb?: Function
): Promise<any> {
  var formdata = new FormData();
  for (var i in data) {
    formdata.append(i, data[i]);
  }
  let tmp: any = await axios.request({
    method: "post",
    url: `${Config.Host}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      Accept: "multipart/form-data",
    },
    onUploadProgress: (p) => {
      if (cb) cb(p);
    },
  });
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}
async function uploadImage(image: any): Promise<any> {
  return postFormData(`${Config.Host}/file/upload-image`, {
    image,
  });
}
async function getQrCode2FA(token: string) {
  return getByToken("/customer/2fa-get-qr-code", token);
}
async function confirmSetup2FA(otp: string, secret: string, token: string) {
  return postByToken("/customer/2fa-setup-confirm", { otp, secret }, token);
}

async function loginWith2FA({ otp, email, password }: any) {
  try {
    let { token, customerInfo } = await post("/dao/2fa-login", {
      otp,
      email,
      password,
      type: LoginType.Email,
      isGame: true,
    });
    localStorage.clear();
    sessionStorage.setItem("token", token);
    return { customerInfo, token };
  } catch (error: any) {
    if (error.code === "customer_is_block") {
      toast(`You have been banned following reason below: ${error.message}`);
      throw new Error();
    }
    throw error;
  }
}
async function getCaptcha(id: string) {
  return getImage(`/captcha/get-image?id=${id}`);
}

async function registerBySocial(
  social_access_token: string,
  type: LoginType,
  referral?: string,
  setShowLogin?: any
) {
  let data;
  ui.showCaptcha(async (captcha_id: string, captcha_nonce: number) => {
    try {
      data = await post("/dao/register-by-social", {
        social_access_token,
        type,
        referral,
        captcha_id,
        captcha_nonce,
      });
      let { gameToken } = data;
      localStorage.clear();
      sessionStorage.setItem("token", gameToken);
      setShowLogin && setShowLogin(false);
      onCustomerSignup(type);
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  });
  return data;
}

async function loginBySocial(social_access_token: string, type: LoginType) {
  try {
    let { customerInfo, token } = await post("/customer/login-customer", {
      social_access_token,
      type,
    });
    localStorage.clear();
    sessionStorage.setItem("token", token);
    return { customerInfo, token };
  } catch (error: any) {
    if (error.message === "customer_is_block") {
      toast(`You have been banned following reason below: ${error.message}`);
      throw new Error();
    }
    throw error;
  }
}
async function sendRegisterEmail(email: string) {
  let data = post("/customer/send-register-email", { email });
  return data;
}
async function sendRegisterEmailPw(
  email: string,
  password: string,
  setShowLogin?: any
) {
  let data;
  ui.showCaptcha(async (captcha_id: string, captcha_nonce: number) => {
    try {
      data = await post("/dao/send-email-register", {
        email,
        password,
        captcha_id,
        captcha_nonce,
      });
      setShowLogin && setShowLogin(false);
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  });
  return data;
}
async function loginAsGuest(setShowLogin?: any) {
  let data;
  ui.showCaptcha(async (captcha_id: string, captcha_nonce: number) => {
    try {
      let { customerInfo, gameToken } = await post("/dao/login-as-guest", {
        captcha_id,
        captcha_nonce,
      });
      sessionStorage.setItem("token", gameToken);
      localStorage.setItem("token-guest", gameToken);
      setShowLogin && setShowLogin(false);
      return { customerInfo, gameToken };
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  });
  return data;
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const api = {
  loginAsGuest,
  onCustomerSignup,
  sendGA,
  post,
  postFormData,
  uploadImage,
  registerWithSavedToken,
  registerBySocial,
  registerMetaMask,
  sendRegisterEmail,
  sendRegisterEmailPw,
  login,
  loginWith2FA,
  loginBySocial,
  getNonce,
  getCaptcha,
  getQrCode2FA,
  confirmSetup2FA,
  loginCosmos,
  delay
};
export default api;
