import {
  getChainOptions,
  WalletController,
} from "@terra-money/wallet-controller";
import { Fee, LCDClient, MsgExecuteContract } from "@terra-money/terra.js";
import {
  ConnectedWallet,
  CreateTxFailed,
  Timeout,
  TxFailed,
  TxResult,
  TxUnspecifiedError,
  UserDenied,
} from "@terra-money/wallet-provider";
import { GasPrice, NFTType } from "../game-interface";

const gasAdjustment = process.env.REACT_APP_GAS_ADJUSTMENT ?? 1.75;

export async function depositMSTR(
  lcd: LCDClient,
  connectedWallet: ConnectedWallet,
  amount: string,
  gasPrice: GasPrice
) {
  try {
    const msg = new MsgExecuteContract(
      connectedWallet.walletAddress,
      process.env.REACT_APP_TERRA_MSTR_ADDRESS,
      {
        transfer: {
          amount,
          recipient: process.env.REACT_APP_ADMIN_ADDRESS,
        },
      }
    );
    const unsignedTx = await lcd.tx.create(
      [{ address: connectedWallet.walletAddress }],
      {
        msgs: [msg],
      }
    );
    const gas =
      unsignedTx.auth_info.fee.gas_limit *
      Number(gasAdjustment);
    return await connectedWallet
      .post({
        msgs: [msg],
        fee: new Fee(gas, Math.ceil(gas * gasPrice.price) + gasPrice.denom),
      })
      .then((nextTxResult: TxResult) => {
        // @ts-ignore
        return nextTxResult.msgs[0].execute_msg.transfer.amount;
      })
      .catch((error: unknown) => {
        if (error instanceof UserDenied) {
          console.log("User Denied");
        } else if (error instanceof CreateTxFailed) {
          console.log("Create Tx Failed: " + error.message);
        } else if (error instanceof TxFailed) {
          console.log("Tx Failed: " + error.message);
        } else if (error instanceof Timeout) {
          console.log("Timeout");
        } else if (error instanceof TxUnspecifiedError) {
          console.log("Unspecified Error: " + error.message);
        } else {
          console.log(
            "Unknown Error: " +
            (error instanceof Error ? error.message : String(error))
          );
        }
        return null;
      });
  } catch (err) {
    return null;
  }
}

export async function depositMAG(
  lcd: LCDClient,
  connectedWallet: ConnectedWallet,
  amount: string,
  gasPrice: GasPrice
) {
  try {
    const msg = new MsgExecuteContract(
      connectedWallet.walletAddress,
      process.env.REACT_APP_TERRA_MAG_ADDRESS,
      {
        transfer: {
          amount,
          recipient: process.env.REACT_APP_ADMIN_ADDRESS,
        },
      }
    );
    const unsignedTx = await lcd.tx.create(
      [{ address: connectedWallet.walletAddress }],
      {
        msgs: [msg],
      }
    );
    const gas =
      unsignedTx.auth_info.fee.gas_limit *
      Number(gasAdjustment);
    return await connectedWallet
      .post({
        msgs: [msg],
        fee: new Fee(gas, Math.ceil(gas * gasPrice.price) + gasPrice.denom),
      })
      .then((nextTxResult: TxResult) => {
        // @ts-ignore
        return nextTxResult.msgs[0].execute_msg.transfer.amount;
      })
      .catch((error: unknown) => {
        if (error instanceof UserDenied) {
          console.log("User Denied");
        } else if (error instanceof CreateTxFailed) {
          console.log("Create Tx Failed: " + error.message);
        } else if (error instanceof TxFailed) {
          console.log("Tx Failed: " + error.message);
        } else if (error instanceof Timeout) {
          console.log("Timeout");
        } else if (error instanceof TxUnspecifiedError) {
          console.log("Unspecified Error: " + error.message);
        } else {
          console.log(
            "Unknown Error: " +
            (error instanceof Error ? error.message : String(error))
          );
        }
        return null;
      });
  } catch (err) {
    return null;
  }
}

export async function burnItems(
  lcd: LCDClient,
  connectedWallet: ConnectedWallet,
  token_ids: string[],
  type: NFTType,
  gasPrice: GasPrice
) {
  try {
    let contractAddress;
    switch (type) {
      case NFTType.Mongen:
        contractAddress = process.env.REACT_APP_TERRA_MONGEN_ADDRESS;
        break;
      case NFTType.SoulCore:
        contractAddress = process.env.REACT_APP_TERRA_SOULCORE_ADDRESS;
        break;
      case NFTType.Plot:
        contractAddress = process.env.REACT_APP_TERRA_PLOT_ADDRESS;
        break;
      case NFTType.Land:
        contractAddress = process.env.REACT_APP_TERRA_LAND_ADDRESS;
        break;
    }

    const msg = new MsgExecuteContract(
      connectedWallet.walletAddress,
      contractAddress,
      {
        stake_batch: {
          token_ids,
        },
      }
    );
    const unsignedTx = await lcd.tx.create(
      [{ address: connectedWallet.walletAddress }],
      {
        msgs: [msg],
      }
    );
    const gas =
      unsignedTx.auth_info.fee.gas_limit *
      Number(gasAdjustment);
    return await connectedWallet
      .post({
        msgs: [msg],
        fee: new Fee(gas, Math.ceil(gas * gasPrice.price) + gasPrice.denom),
      })
      .then((nextTxResult: TxResult) => {
        return nextTxResult;
      })
      .catch((error: unknown) => {
        if (error instanceof UserDenied) {
          console.log("User Denied");
        } else if (error instanceof CreateTxFailed) {
          console.log("Create Tx Failed: " + error.message);
        } else if (error instanceof TxFailed) {
          console.log("Tx Failed: " + error.message);
        } else if (error instanceof Timeout) {
          console.log("Timeout");
        } else if (error instanceof TxUnspecifiedError) {
          console.log("Unspecified Error: " + error.message);
        } else {
          console.log(
            "Unknown Error: " +
            (error instanceof Error ? error.message : String(error))
          );
        }
        return null;
      });
  } catch (err) {
    return null;
  }
}

export async function burnEvoItem(
  lcd: LCDClient,
  connectedWallet: ConnectedWallet,
  amount: number,
  type: NFTType,
  gasPrice: GasPrice
) {
  try {
    let contractAddress;
    switch (type) {
      case NFTType.EvolutionItem:
        contractAddress = process.env.REACT_APP_TERRA_EVOLUTION_ADDRESS;
        break;
      case NFTType.NormalStone:
        contractAddress = process.env.REACT_APP_TERRA_NORMAL_STONE_ADDRESS;
        break;
      case NFTType.RerollStone:
        contractAddress = process.env.REACT_APP_TERRA_REROLL_STONE_ADDRESS;
        break;
      case NFTType.EliteStone:
        contractAddress = process.env.REACT_APP_TERRA_ELITE_STONE_ADDRESS;
        break;
      case NFTType.NormalRuneStone:
        contractAddress = process.env.REACT_APP_TERRA_NORMAL_RUNE_STONE_ADDRESS;
        break;
      case NFTType.RerollRuneStone:
        contractAddress = process.env.REACT_APP_TERRA_REROLL_RUNE_STONE_ADDRESS;
        break;
      case NFTType.EliteRuneStone:
        contractAddress = process.env.REACT_APP_TERRA_ELITE_RUNE_STONE_ADDRESS;
        break;
    }

    const msg = new MsgExecuteContract(
      connectedWallet.walletAddress,
      contractAddress,
      {
        burn: {
          amount: amount.toString(),
        },
      }
    );

    const unsignedTx = await lcd.tx.create(
      [{ address: connectedWallet.walletAddress }],
      {
        msgs: [msg],
      }
    );
    const gas =
      unsignedTx.auth_info.fee.gas_limit *
      Number(gasAdjustment);

    console.log("dcmdsm");

    return await connectedWallet
      .post({
        msgs: [msg],
        fee: new Fee(gas, Math.ceil(gas * gasPrice.price) + gasPrice.denom),
      })
      .then((nextTxResult: TxResult) => {
        return nextTxResult;
      })
      .catch((error: unknown) => {
        if (error instanceof UserDenied) {
          console.log("User Denied");
        } else if (error instanceof CreateTxFailed) {
          console.log("Create Tx Failed: " + error.message);
        } else if (error instanceof TxFailed) {
          console.log("Tx Failed: " + error.message);
        } else if (error instanceof Timeout) {
          console.log("Timeout");
        } else if (error instanceof TxUnspecifiedError) {
          console.log("Unspecified Error: " + error.message);
        } else {
          console.log(
            "Unknown Error: " +
            (error instanceof Error ? error.message : String(error))
          );
        }
        return null;
      });
  } catch (err) {
    return null;
  }

}
