import { Dialog } from "@headlessui/react";
import api from "api";
import { t } from "i18next";
import Slider from "rc-slider";
import { useEffect, useState } from "react";
// import { Modal } from "semantic-ui-react";

export const CaptchaV2 = ({ cb, closeModal }: any) => {
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    // @ts-ignore
    const [id, setId] = useState(window.create_UUID());
    const [imgBackground, setImgBackground] = useState("");
    const MAX_VALUE = 500 - 93;

    const getCaptcha = async () => {
        try {
            const res = await api.getCaptcha(id);
            const imageObjectURL = URL.createObjectURL(res);
            setImgBackground(imageObjectURL);
        } catch (err) {
            console.log({ err })
        }
    }

    const verifyCaptcha = async (amount: number) => {
        try {
            setLoading(true)
            const nonce = amount / MAX_VALUE
            await cb(id, nonce);
            closeModal();
        } catch (err: any) {
            setAmount(0);
            closeModal();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getCaptcha();
    }, [id])

    return (
        <div className="absolute bg-popup w-full h-full top-0 left-0">

            <div className="absolute w-full h-full mx-auto" style={{
                top: "calc(50% - 240px)"
            }}>
                <div
                    key={"captcha"}
                    className="max-w-[540px] min-w-[540px] w-screen relative alert-popup bg-black mx-auto text-center self-center"
                >
                    {imgBackground && <div className="max-w-[540px] min-w-[540px] py-8 h-full w-screen relative center ">
                        <div className="text-2xl text-white mb-10">
                            {t("captcha_desc")}
                        </div>
                        <div className="max-w-[500px] relative  mx-[20px]">
                            <img src={imgBackground} alt="captcha-bg" className="w-[500px]" id="captcha-bg" />
                            <img src="/assets/images/slider.png" alt="slider" className="absolute w-[97px]" id="captcha-slider"
                                style={{
                                    top: "100px",
                                    transform: `translateX(${amount * 1}px)`
                                }}
                            />
                            {loading && <div className="absolute text-white top-[100px] mx-auto self-center justify-center w-full">
                                <div className="loader mx-auto"></div>
                            </div>}
                            <Slider
                                disabled={loading}
                                className="captcha-slider mt-2 mb-4"
                                min={0}
                                max={MAX_VALUE}
                                step={1}
                                value={amount}
                                onChange={(e) => {
                                    setAmount(Number(e));
                                }}
                                trackStyle={{
                                    background: "#E89F01"
                                }}
                                dotStyle={{
                                    borderColor: "unset",
                                    boxShadow: "unset",
                                    border: "unset !important"
                                }}
                                activeDotStyle={{
                                    borderColor: "unset",
                                    boxShadow: "unset",
                                    border: "unset !important"
                                }}
                                style={{
                                    cursor: "pointer",
                                    width: "100%"
                                }}
                                onAfterChange={async (e) => {
                                    await verifyCaptcha(Number(e))
                                }}
                            />
                        </div>
                    </div>}
                    <div className="text-red cursor-pointer absolute -right-2 -top-2" onClick={() => {
                        if (!loading)
                            closeModal();
                    }}>
                        <img src="/assets/images/close-icon.svg" alt="close" className="" />
                    </div>
                </div>
            </div>
        </div>
    )
}