import api from "api";
import { t } from "i18next";
import { useEffect, useState } from "react";

export default function TwoFactorAuthenticator({
  open,
  onClose,
  onSuccess,
  token,
}: any) {
  const [isContinue, setIsContinue] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [qrCodeData, setQrCode] = useState(null);

  const resetState = () => {
    setIsContinue(false);
    setTextInput("");
    setQrCode(null);
  };

  const onSubmit = () => {
    api
      .confirmSetup2FA(textInput, qrCodeData?.secret ?? "motconvit", token)
      .then((data) => {
        onSuccess();
        onClose();
        resetState();
        localStorage.clear();
        sessionStorage.setItem("token", token);
      })
      .catch((err) => {
        onClose();
        resetState();
      });
  };

  useEffect(() => {
    api
      .getQrCode2FA(token)
      .then((data) => setQrCode(data))
      .catch(console.log);
  }, []);

  return (
    <div className="py-4">
      {!isContinue && (
        <div>
          <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
            {t("second_authentication_factor")}
          </div>
          <div className="w-11/12 mx-auto text-white text-center">
            <div className="col-span-1">
              <div className="mb-4">
                {t("second_authentication_factor_scan_qr")}
              </div>
              {
                <img
                  className="text-center mx-auto"
                  src={qrCodeData?.qrImage}
                  width={200}
                  height={200}
                  alt="qr_code"
                />
              }
            </div>
            <div className="text-white text-lg col-span-1 mt-4 self-center">
              <div>{t("second_authentication_factor_secret_key")} {qrCodeData?.secret}</div>
            </div>
            <div className="text-left mt-2 text-sm">
              {t("second_authentication_factor_secret_key_alert_1")}
              <div className="mt-2">
                {t("second_authentication_factor_secret_key_alert_2")}
              </div>
            </div>
          </div>

          <div
            className={`flex w-full justify-center gap-4 my-6 text-center items-center mx-auto`}
          >
            <button
              color="yellow"
              className="px-8 py-2"
              onClick={(e) => {
                e.preventDefault();
                setIsContinue(true);
              }}
            >
              <div className="text-lg text-white font-semibold">{t("continue")}</div>
            </button>
          </div>
        </div>
      )}
      {isContinue && (
        <div className="w-full">
          <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
            {t("second_authentication_factor")}
          </div>
          <div className="w-11/12 mx-auto">
            <div className="mb-6 text-white">
              {t("second_authentication_factor_finish_step")}
            </div>
            <div className="text-center mx-auto justify-center w-full">
              <input
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                placeholder={t("enter_verify_code")}
                type="text"
                className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
              />
            </div>
          </div>
          <div
            className={`flex w-full justify-center gap-4 mt-10 text-center items-center mx-auto`}
          >
            <button
              color="yellow"
              className="px-8 py-2"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="text-white font-semibold">{t("active")}</div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
