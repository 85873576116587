import dayjs from "dayjs";
const isValidPassword = (password: string) => {
  if (!/^(?=.*[A-Z])[a-zA-Z\d]{8,16}$/.test(password)) {
    return false;
  }
  return true;
};


const getCountdownText = (date: any) => {
  let diff = dayjs(date).diff(dayjs(), "second");
  let day = Math.floor(diff / 86400) + "";
  let hour = Math.floor((diff % 86400) / 3600) + "";
  let min = Math.floor((diff % 3600) / 60) + "";
  let second = (diff % 60) + "";
  return {
    "days": day.padStart(2, "0"),
    "hours": hour.padStart(2, "0"),
    "minutes": min.padStart(2,"0"),
    "seconds": second.padStart(2, "0")
  }
}

function containsSpecialChars(string: string) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(string);
}

const utils = {
  isValidPassword,
  getCountdownText,
  containsSpecialChars
};

export default utils;
