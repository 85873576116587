import { t } from "i18next";

export default function Loading() {
  return (
    <div className="p-4">
      <div
        className="bg-[#000000c2] p-4 text-white rounded-xl overflow-hidden w-[600px]"
        style={{ paddingBottom: 25 }}
      >
        <div className="flex text-green-400">
          <div className="text-sm text-center w-full">
            <p className="text-4xl w-full text-center font-clock font-bold">
              {t("loading_message")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
