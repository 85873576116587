import api from "api";
import { LoginType } from "game-interface";
import { t } from "i18next";
import { useState } from "react";

export const Signup = ({
  token,
  loginType,
  closeModal,
  setShowLogin,
  password,
  referral,
}: any) => {
  const [loading, setLoading] = useState(false);
  const registerBySavedToken = async () => {
  console.log({ referral })
    try {
      await api.registerWithSavedToken(
        token,
        LoginType.SavedToken,
        setShowLogin,
        password,
        referral
      );
      closeModal();
    } catch (err) {
      throw err;
    } finally {
      closeModal();
    }
  };

  return (
    <div className="absolute bg-popup w-full h-full top-0 left-0">
      <div
        className="absolute w-full h-full mx-auto"
        style={{
          top: "calc(50% - 200px)",
        }}
      >
        <div className="w-full">
          <div
            className="bg bg-contain px-1 w-[510px] max-w-full mx-auto text-center"
            style={{ paddingBottom: 25 }}
          >
            <p className="text-3xl py-4 text-white pt-6">{t("user_not_found_title")}</p>
            <div className="bg-[#F3E9DF] py-16 text-[#747594]">
              <div>
                {t("user_not_found_1")}
              </div>
              <div>
                {t("user_not_found_2")}
              </div>
            </div>
            <div className="mt-6 mx-14">
              <button
                className="w-full h-[48px] w-[110px] red-art-button mr-8 text-white"
                color="red"
                onClick={closeModal}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="w-full h-[48px] w-[110px] green-art-button ml-8 text-white"
                color="yellow"
                disabled={loading}
                onClick={registerBySavedToken}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
