import { combineReducers, configureStore } from "@reduxjs/toolkit";
import modals from "./modals.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "userInfo",
  storage,
  blacklist: ["modals"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    modals,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
