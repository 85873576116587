import api from "api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import utils from "service/utils";

export const SignupByEmail = ({ token, username, closeModal, setShowLogin }: any) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [isRegister, setIsRegister] = useState(false);
  const [input, setInput] = useState<any>({});
  const [isNotDone, setIsNotDone] = useState(true);
  const [isHidePassword, setIsHidePassword] = useState(true);
  const [isHideRePassword, setIsHideRePassword] = useState(true);

  function handleChange(name: string, value: any) {
    let tmp: any = { ...input };
    tmp[name] = value;
    setInput(tmp);
  }

  function onClose() {
    closeModal();
  }

  async function register() {
    try {
      if (input.password !== input.repassword) {
        throw new Error("Password and confirm password do not match. Please try again.")
      }
      if (!utils.isValidPassword(input.password)) {
        throw new Error(t("password_not_match_rule"));
      }
      await api.sendRegisterEmailPw(username, input.password, setIsNotDone);
    } catch (err: any) {
      if (err.message) {
        toast(t(err.message));
      }
    }
  }

  if (!isNotDone) {
    return (
      <div className="absolute bg-popup w-full h-full top-0 left-0">
        <div
          className="absolute w-full h-full mx-auto"
          style={{
            top: "calc(50% - 200px)",
          }}
        >
          <div className="w-full">
            <div
              className="bg bg-contain px-1 w-[520px] max-w-full mx-auto text-center"
              style={{ paddingBottom: 25 }}
            >
              <p className="text-3xl py-4 text-white pt-6">{t("congratulation_title")}</p>
              <div className="bg-[#F3E9DF] py-16 text-[#747594]">
                <div>
                  {t("congratulation_desc_1")}
                </div>
                <div>
                  {t("congratulation_desc_2")}
                </div>
                <div>
                  {username}
                </div>
              </div>
              <div className="mt-6 mx-14">
                <button
                  className="w-full h-[48px] w-[140px] green-art-button ml-8 text-white"
                  color="yellow"
                  disabled={loading}
                  onClick={() => {
                    let siteUrl = "https://gmail.com";
                    window.open(siteUrl, "_blank");
                  }}>
                  {t("open_email")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (isRegister) {
    return (
      <div className="w-screen">
        <div
          className="bg bg-contain px-1 w-[500px] max-w-full mx-auto text-center"
          style={{ paddingBottom: 25 }}
        >
          <p className="text-5xl py-4 text-outline">{t("email_or_username")}</p>
          <form
            className="bg-[#22253a] p-4"
            onSubmit={async (evt) => {
              evt.preventDefault();
            }}
          >
            <div className="mt-4">
              <input
                value={username}
                disabled={true}
                placeholder={t("email_or_username")}
                type="text"
                className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
              />
            </div>
            <div className="mt-2 relative">
              <input
                onChange={(evt: any) => {
                  if (evt.target.value && utils.containsSpecialChars(evt.target.value)) {
                    return;
                  }
                  handleChange("password", evt.target.value);
                }}
                value={input["password"] ?? ""}
                placeholder={t("password")}
                type={isHidePassword ? "password" : 'text'}
                className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
              />
              <img src={require(`../images/${isHidePassword ? 'eye-slash-solid' : 'eye-solid'}.svg`)}
                className="absolute w-8 h-8 right-4 top-0 translate-y-[15px] cursor-pointer" alt="password"
                onClick={() => {
                  setIsHidePassword(!isHidePassword)
                }}
              />
            </div>
            <div className="mt-2 relative">
              <input
                onChange={(evt: any) => {
                  if (evt.target.value && utils.containsSpecialChars(evt.target.value)) {
                    return;
                  }
                  handleChange("repassword", evt.target.value);
                }}
                value={input["repassword"] ?? ""}
                placeholder={t("repassword")}
                type={isHideRePassword ? "password" : 'text'}
                className="w-full text-2xl text-center p-2 bg-[#5b628a] rounded-md mt-2 text-white"
              />
              <img src={require(`../images/${isHideRePassword ? 'eye-slash-solid' : 'eye-solid'}.svg`)}
                className="absolute w-8 h-8 right-4 top-0 translate-y-[15px] cursor-pointer" alt="password"
                onClick={() => {
                  setIsHideRePassword(!isHideRePassword)
                }}
              />
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                className="p-2 large w-full mt-3"
                onClick={() => {
                  onClose();
                }}
              >
                <p className="text-outline">{t("back")}</p>
              </button>
              <button type="submit" className="p-2 large w-full mt-3"
                onClick={async () => await register()}
              >
                <p className="text-outline">
                  {t("register")}
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  return (
    <div className="absolute bg-popup w-full h-full top-0 left-0">
      <div
        className="absolute w-full h-full mx-auto"
        style={{
          top: "calc(50% - 200px)",
        }}
      >
        <div className="w-full">
          <div
            className="bg bg-contain px-1 w-[510px] max-w-full mx-auto text-center"
            style={{ paddingBottom: 25 }}
          >
            <p className="text-3xl py-4 text-white pt-6">{t("user_not_found_title")}</p>
            <div className="bg-[#F3E9DF] py-16 text-[#747594]">
              <div>
                {t("user_not_found_1")}
              </div>
              <div>
                {t("user_not_found_2")}
              </div>
            </div>
            <div className="mt-6 mx-14">
              <button
                className="w-full h-[48px] w-[110px] red-art-button mr-8 text-white"
                color="red"
                onClick={() => onClose()}
                disabled={loading}
              >
                {t("cancel")}
              </button>
              <button
                className="w-full h-[48px] w-[110px] green-art-button ml-8 text-white"
                color="yellow"
                disabled={loading}
                onClick={() => setIsRegister(true)}
              >
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}